import { API, Auth } from "aws-amplify";
import { format } from "date-fns";
import React from "react";
import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import config from "../config.json";
import Constant from "../Constant";

export default function ConfirmationList(props) {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  return (
    <ConfirmationListComp
      {...props}
      navigate={navigate}
      cookies={cookies}
      setCookie={setCookie}
    />
  );
}

class ConfirmationListComp extends React.Component {
  constructor(props, cookies, setCookie) {
    super(props);
    this.cookies = props.cookies;
    this.setCookie = props.setCookie;
    this.state = {
      api_name: config.stage,
      reservations: [],
    };
    this.getReservations();
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = await user.signInUserSession.idToken.jwtToken;
    const myinit = await {
      headers: {
        Authorization: token,
      },
    };

    await this.setState({
      token: token,
      myinit: myinit,
    });
  };

  getReservations = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myinit = {
      headers: {
        Authorization: token,
      },
    };

    await API.get(this.state.api_name, "/reservations", myinit)
      .then((r) => {
        this.setState({
          reservations: r,
        });
      })
      .catch((e) => {
        toast.error("予約データの取得エラー");
      });
  };

  disabledCancel(reservation) {
    const now = new Date();
    if (reservation.reservation_date <= format(now, "yyyy-MM-dd")) {
      return true;
    }
    // 明日
    if (
      reservation.reservation_date ==
      format(
        new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1),
        "yyyy-MM-dd"
      )
    ) {
      // 普通
      if (reservation.car_type == Constant.CAR_TYPE_1) {
        if (
          reservation.inspection_type == Constant.INSPECTION_TYPE_1 ||
          reservation.inspection_type == Constant.INSPECTION_TYPE_2
        ) {
          if (
            reservation.round == Constant.ROUND_1 ||
            reservation.round == Constant.ROUND_2
          ) {
            if (
              now.getTime() >
              new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate(),
                "15",
                "00",
                "00"
              ).getTime()
            ) {
              return true;
            }
          } else if (
            reservation.round == Constant.ROUND_3 ||
            reservation.round == Constant.ROUND_4
          ) {
            if (
              now.getTime() >
              new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate(),
                "17",
                "00",
                "00"
              ).getTime()
            ) {
              return true;
            }
          }
        } else if (reservation.inspection_type == Constant.INSPECTION_TYPE_3) {
          if (
            now.getTime() >
            new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate(),
              "12",
              "00",
              "00"
            ).getTime()
          ) {
            return true;
          }
        } else if (reservation.inspection_type == Constant.INSPECTION_TYPE_4) {
          if (
            now.getTime() >
            new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate(),
              "17",
              "00",
              "00"
            ).getTime()
          ) {
            return true;
          }
        }
        // 軽
      } else if (reservation.car_type == Constant.CAR_TYPE_2) {
        if (
          reservation.round == Constant.ROUND_1 ||
          reservation.round == Constant.ROUND_2
        ) {
          if (
            now.getTime() >
            new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate(),
              "15",
              "00",
              "00"
            ).getTime()
          ) {
            return true;
          }
        } else if (
          reservation.round == Constant.ROUND_3 ||
          reservation.round == Constant.ROUND_4
        ) {
          if (
            now.getTime() >
            new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate(),
              "17",
              "00",
              "00"
            ).getTime()
          ) {
            return true;
          }
        }
      }
    }

    return false;
  }

  render() {
    const reservation_html = [];
    const reservations = this.state.reservations;
    for (let i = 0; i < reservations.length; i++) {
      reservation_html.push(
        <tr key={i}>
          {/*}
          <td style={{ textAlign: "center" }}>
            <button
              type="button"
              className="btn btn-sm btn-warning"
              onClick={() => {
                this.props.navigate(
                  "/cancellation_confirm/" + reservations[i].id
                );
              }}
              // TODO
              disabled={
                //reservations[i].reservation_date ==
                //format(new Date(), "yyyy-MM-dd")
                //this.disabledCancel(reservations[i]) ? true : false
                true
              }
            >
              キャンセル
            </button>
          </td>
          */}
          <td>{reservations[i].reservation_date}</td>
          <td>
            {reservations[i].round
              ? Constant.ROUND_LIST[reservations[i].round]
              : ""}
          </td>
          <td>{reservations[i].reservation_no}</td>
          <td>{Constant.CAR_TYPE_LIST[reservations[i].car_type]}</td>
          <td>
            {Constant.INSPECTION_TYPE_LIST[reservations[i].inspection_type]}
          </td>
          <td>{reservations[i].body_no}</td>
          <td>{reservations[i].plate_no}</td>
        </tr>
      );
    }

    return (
      <div className="content-wrapper">
        <Toaster />
        {/* Content Wrapper. Contains page content */}
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">予約確認</h3>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-primary card-outline">
              <div className="card-body table-responsive">
                <table className="table table-sm table-bordered table-hover table-head-fixed text-nowrap">
                  <thead>
                    <tr>
                      {/* <th style={{ width: "100px" }}>キャンセル</th> */}
                      <th>予約日</th>
                      <th>ラウンド</th>
                      <th>予約番号</th>
                      <th>予約の種類</th>
                      <th>検査の種類</th>
                      <th>車体番号</th>
                      <th>ナンバー</th>
                    </tr>
                  </thead>
                  <tbody>{reservation_html}</tbody>
                </table>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
        {/* /.content-wrapper */}
      </div>
    );
  }
}
